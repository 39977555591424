//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'UiTileImage',
  props: {
    blok: {
      type: Object,
      required: true,
    },
    parentIndex: {
      type: Number,
      default: null,
    },
  },
  computed: {
    width() {
      switch(this.blok.size) {
        case 'big':
          return 430;
        case 'wide':
          return 445;
        default:
          return 217;
      }
    },
    height() {
      switch(this.blok.size) {
        case 'big':
          return 414;
        default:
          return 202;
      }
    },
  },
}
